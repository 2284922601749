<template>
  <div>
    <el-form
      :rules="rules"
      :model="selectedBranch"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop="" label="Наименование филиала">
            <el-row :key="key" v-for="(objName,key) in selectedBranch.listName">
              <el-col :span="7">
                <el-input v-model="objName.name"></el-input>
              </el-col>
              <el-col :span="7">
                <el-date-picker
                  v-model="objName.dateFrom"
                  type="date"
                  placeholder = "Дата старта наименования"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
              <el-col :span="7">
                <el-date-picker 
                  v-model="objName.dateTo"
                  type="date"
                  placeholder = "Дата конца наименования"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-col>
              <el-col :span="3">
                <el-button @click="deleteLine(key)" type="primary" style="float: left" 
                  >Удалить</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-button @click="addLine" type="primary" style="float: left" 
                  >Добавить</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="email" label="Почта">
            <el-input v-model="selectedBranch.email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <h4>Фактический адрес нахождения подразделения</h4>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="region" label="Область">
            <el-select
              clearable
              class="searchPole"
              v-model="selectedBranch.areaInfo"
              value-key="id"
              placeholder="Область"
            >
              <el-option
                v-for="item in areaNames.content"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="district" label="Район">
            <el-select
              clearable
              :disabled="selectedBranch.areaInfo === ''"
              class="searchPole"
              v-model="selectedBranch.districtInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Район"
              :remote-method="searchDistrictNames"
            >
              <el-option
                v-for="item in districtNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="locality" label="Населенный пункт">
            <el-select
              clearable
              :disabled="selectedBranch.districtInfo === ''"
              class="searchPole"
              v-model="selectedBranch.cityInfo"
              filterable
              remote
              value-key="id"
              reserve-keyword
              placeholder="Населенный пункт"
              :remote-method="searchCitiesNames"
            >
              <el-option
                v-for="item in citiesNames"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="street" label="Улица">
            <el-input v-model="selectedBranch.street"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="phoneNumber" label="Телефон">
            <el-input v-model="selectedBranch.phoneNumber"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="selectedBranch.dateFrom"
                  type="date"
                  placeholder = "Дата начала работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <br>
      <el-row>
        <input type="checkbox" id="checkbox" v-model="selectedBranch.datEd">
        <label for="checkbox">Устаревшее</label>
      </el-row>
      <el-row >
        <el-date-picker 
                  v-model="selectedBranch.dateTo"
                  type="date"
                  :disabled="!selectedBranch.datEd"
                  placeholder = "Выберите дату"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <el-row>
        <el-button @click="save" type="primary">Изменить</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";

export default {
  name: "branchesEdit",
  props: ["selectedBranch"],
  mixins: [notification],
  data() {
    return {
      rules: {
        title: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      areaNames: "GETLISTALLAREAS",
      districtNames: "GETLISTDISTRICNAMES",
      citiesNames: "GETLISTCITIESNAMES",
      responsiblePersonList: "GETRESPONSIBLEPERSONLIST",
    }),
  },

  methods: {
    deleteLine(index)
    {
      this.selectedBranch.listName.splice(index, 1);
    },
    addLine(){
      console.log(this);
      this.selectedBranch.listName.push({
        name: "",
        datEd: null,
        dateTo: null
      });
    },
    searchResponsiblePerson(query) {
      this.searchResponsiblePersonByQuery(query);
    },

    searchDistrictNames(query) {
      this.getDistrictNames(query);
    },

    searchCitiesNames(query) {
      this.getCitiesNames(query);
    },

    changeArea() {},

    changeDistrict() {},

    save() {
      this.selectedBranch.title=this.selectedBranch.listName[this.selectedBranch.listName.length-1].name;

      this.selectedBranch.areaId = this.selectedBranch.areaInfo.id != -1 && this.selectedBranch.areaInfo !="" ? this.selectedBranch.areaInfo.id : null;
      this.selectedBranch.districtId = this.selectedBranch.districtInfo.id!= -1 && this.selectedBranch.districtInfo !="" ?  this.selectedBranch.districtInfo.id : null;
      this.selectedBranch.areaInfo = this.selectedBranch.areaInfo !="" ? this.selectedBranch.areaInfo : null;
      this.selectedBranch.districtInfo = this.selectedBranch.districtInfo !="" ? this.selectedBranch.districtInfo : null;
      this.selectedBranch.cityInfo = this.selectedBranch.cityInfo !=""? this.selectedBranch.cityInfo : {id:-1};
      this.selectedBranch.street = this.selectedBranch.street !="" ? this.selectedBranch.street : null;
      
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("updateOrganizationUnitInfo", this.selectedBranch)
            .then(() => {
              this.notification("Успешно", "Филиал обновлен", "success");
              this.$store.dispatch("getListUnits", 1);
              this.$store.dispatch("setShowModal", null);
            });
        }
      });
    },

    getListAllAreas() {
      this.$store.dispatch("getListAllAreas", {
        page: 0,
        size: 20,
      });
    },

    getDistrictNames(query) {
      this.$store.dispatch("getDistrictNames", {
        areaId: this.selectedBranch.areaInfo.id,
        query: query,
      });
    },

    getCitiesNames(query) {
      this.$store.dispatch("getCitiesNamesForUser", {
        areaId: this.selectedBranch.areaInfo.id,
        districtId: this.selectedBranch.districtInfo.id,
        query: query,
      });
    },

    searchResponsiblePersonByQuery(query) {
      this.$store.dispatch("searchResponsiblePerson", {
        page: 0,
        size: 20,
        query: query,
      });
    },

    getAllResponsiblePerson() {
      this.$store.dispatch("getAllResponsiblePerson", { page: 0, size: 20 });
    },
  },

  mounted() {
    this.getListAllAreas();
    this.getAllResponsiblePerson();
  },
};
</script>

<style scoped></style>
